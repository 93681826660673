import React from 'react'

const Disclaimer = ({appName}) => (
  <div style={{padding: '3vw'}}>
    <h4>DISCLAIMER</h4>
    <p>Last updated October 03, 2022</p>
    <div style={{color: "#000000"}}>
      <p>The information provided by our mobile application is for general informational purposes only. All information on our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on our mobile application.</p>
      <p>The rates published here are informational, and may differ from your bank's official exchange rates. Rates can change any time without notice.</p>
      <p>UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON OUR MOBILE APPLICATION. YOUR USE OF OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</p>
    </div>
  </div>
);
                


export default Disclaimer;