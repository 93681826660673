import React from "react";
import _data from "../../data";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      aboutUs: _data.aboutUs
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section id="about" className="about-us ptb-100 background-shape-img">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-7">
                  <div className="about-content-left section-heading">
                    <h2>
                    We create native apps for Android and iOS<br />
                      <span>using React-Native.</span>
                    </h2>

                    <div className="single-feature mb-4 mt-5">
                      <div className="icon-box-wrap d-flex align-items-center mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-1.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        
                        <p className="mb-0">
                        <h5>
                        Write Once and Use Everywhere
                        </h5>
                        By far the best feature and actual concept on what is based, code that is written in React Native work on almost every mobile platform. It includes IOS, Android, Windows, etc. We do not need to write code in Swift for IOS, java for android or C# for windows. The applications built using React native are native, that means, they work on multiple platforms.
                        </p>
                      </div>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-2.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                        <h5>
                        Tried and Trusted
                        </h5>
                        React Native is one the most trusted framework when it comes to cross-platform application development. Built by an IT giant like Facebook, it is tried enough to be trusted. Facebook uses React Native in all mobile applications. This is enough to trust React Native. Moreover, it used by many other companies such as Instagram Airbnb, Skype, Tesla, Walmart, and Discord.
                        </p>
                      </div>
                      <p></p>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-3.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                        <h5>
                        Development Time
                        </h5>
                        React Native combines the best parts of native development with React making the development time considerably short.
                        </p>
                      </div>
                      <p></p>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-4.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                        <h5>
                        GPU-oriented
                        </h5>
                        Most of the native applications are CPU oriented while the applications built using React Native are GPU-oriented. This leads to better performance than CPU-oriented applications.
                        </p>
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="about-content-right d-flex justify-content-center justify-content-lg-end justify-content-md-end">
                    <img
                      src="img/image-11.png"
                      alt="about us"
                      className="img-fluid"
                    />
                    >
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
