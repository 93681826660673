import axios from 'axios'
import React from "react";
import { connect } from "react-redux";
import { gapi } from 'gapi-script';

class HeroSection extends React.Component {
  
  componentDidMount() {

    let client_id = '466332859027-cbj1lki6gfl12mircf4nt1a412d74vfe.apps.googleusercontent.com'
    let scope = 'profile'
    // this is taken directly from Google documentation:
    // https://developers.google.com/api-client-library/javascript/start/start-js
    function start() {
      // 2. Initialize the JavaScript client library.
      gapi.client.init({
        'apiKey': '1a5ecc66afc575680d88cc7bd887029e5b368bb1Y',
        // clientId and scope are optional if auth is not required.
        'clientId': client_id,
        'scope': scope,
      }).then(function() {
        // 3. Initialize and make the API request.
        return gapi.client.request({
          'path': 'https://www.googleapis.com/auth/admob.readonly',
        })
      }).then(function(response) {
        console.log(response);
        gapi.auth2.getAuthInstance().signIn()
        .then((arg)=>{
          let bearerToken = arg.xc.access_token
          if (bearerToken) {
            // return gapi.client.request({
            //   'path': 'https://admob.googleapis.com/v1/accounts/pub-2403789414453148',
            // })
            axios.get(
              "https://admob.googleapis.com/v1/accounts/pub-2403789414453148",
              {headers: {"Authorization" : `Bearer ${bearerToken}`}
            })
            .then(res => console.log(res))
            .catch(e => console.log(e))
          }
        }, (error) => 
          { 
            if (error) alert('please allow popup for this app')
        })
        // gapi.auth.authorize(
        //   { client_id, scope, immediate: false },
        //   authResult => {
        //     if (authResult && !authResult.error) {
        //       console.log(authResult)
        //         /* handle successful authorization */
        //     } else {
        //       console.log(authResult)
        //         /* handle authorization error */
        //     }
        // });
      }, function(reason) {
        console.log(reason)
        // console.log('Error: ' + reason?.result?.error?.message);
      });
    };
    // 1. Load the JavaScript client library.
    gapi.load('client', start);
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default connect(state => ({
  state,
}))(HeroSection);
