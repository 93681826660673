import React from "react";

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="download-section pt-100 background-img-blue"
          style={{
            backgroundImage: "url('img/dogs-pedia-background-img.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="download-content text-white pb-100">
                  <h2 className="text-white">
                  Dogs Pedia
                  </h2>
                  <p className="lead">
                  A fun and interactive encyclopedia of Dogs Breeds for all the Dog Lovers. <br/>
                  From breed identification to breed selection, this Dog Identifier app is your very own mini-encyclopedia about everything related to dogs.
                  </p>
                  <div className="download-btn">
                    <a href="https://play.google.com/store/apps/details?id=com.quickpedia.dogs" target="_blank" className="btn google-play-btn mr-3">
                      <span className="ti-android"></span> Google Play
                    </a>
                    <a href="https://apps.apple.com/app/id1498410213" target="_blank" className="btn app-store-btn">
                      <span className="ti-apple"></span> App Store
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/dogspedia-main.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{height: 80}}/>
        </section>
      </React.Fragment>
    );
  }
}

export default Download;
